<template>
  <ModalCart :product="modalProduct" :cartType="cartType"> </ModalCart>
  <section class="section mb-9">
    <div class="container">
      <div class="row">
        <div class="col-lg-8 offset-lg-2">
          <SearchForm
            placeholder="What are you looking for?"
            @update:search="searchUpdate"
          ></SearchForm>
        </div>
      </div>
    </div>
  </section>

  <section
    class="section search list list--search-items mb-9"
    v-if="searchValueResult || $route.query.q"
  >
    <div class="container">
      <div class="row">
        <div class="col-lg-8 offset-lg-2">
          <div class="section__header section__header--left mb-3">
            <h4 class="section__title search-results__title">
              {{ $t("search.search-results") }} "{{ searchValueResult }}"
            </h4>

            <div
              class="section__meta search-results__meta d-flex justify-content-between align-items-center text-sm"
            >
              <div>
                <span> {{ searchProducts.total_count }} results </span>
              </div>
              <div class="d-flex align-items-center flex-shrink-0">
                <label
                  for="sort-by"
                  class="mb-0 mr-3"
                  v-t="'miscellaneous.labels.sort-by'"
                  >Sort by:</label
                >
                <div class="custom-control custom-selectbox">
                  <select
                    class="form-control form-control-sm"
                    name="sort"
                    v-model="sortOption"
                    @change="sortChanged()"
                  >
                    <option
                      value="relevance:DESC"
                      data-sort="-relevance"
                      selected
                      v-t="'miscellaneous.labels.relevance'"
                      >Relevance</option
                    >
                    <!-- <option value="price:DESC" data-sort="-price"
                      >Highest price</option
                    >
                    <option value="price:ASC" data-sort="price"
                      >Lowest price</option
                    > -->
                    <option
                      value="name:ASC"
                      data-sort="name"
                      v-t="'miscellaneous.labels.name-a-z'"
                      >Name A-Z</option
                    >
                    <option
                      value="name:DESC"
                      data-sort="-name"
                      v-t="'miscellaneous.labels.name-z-a'"
                      >Name Z-A</option
                    >
                  </select>

                  <!-- <select
                    class="form-control form-control-sm"
                    name="sort"
                    @change="changeSort($event)"
                    v-model="sortValue"
                  >
                    <option value="relevance:DESC" data-sort="relevance-desc"
                      >Relevance</option
                    >
                    <option value="price:DESC" data-sort="-price" selected
                      >Highest price</option
                    >
                    <option value="price:ASC" data-sort="price"
                      >Lowest price</option
                    >
                    <option value="name:ASC" data-sort="name">Name A-Z</option>
                    <option value="name:DESC" data-sort="-name"
                      >Name Z-A</option
                    >
                  </select> -->
                </div>
              </div>
            </div>

            <hr class="my-3" />
          </div>
          <div class="search-results">



            <div v-if="!searchProducts.total_count && searchProducts.total_count !== 0 ">
              <ContentLoader
                viewBox="0 0 1180 620"
                style="width: 100%;"
              >
                <rect x="0" y="0" width="1180" height="200" />
                <rect x="0" y="220" width="1180" height="200" />
                <rect x="0" y="440" width="1180" height="200" />
              </ContentLoader>
            </div>

            <ul class="list-view list-inline">
              <template v-for="(product, i) in searchProducts.items" :key="i">
                <ProductListview
                  :product="product"
                  @update:addedProduct="initProductModal"
                ></ProductListview>
              </template>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>



  <Pagination
    v-if="searchValueResult && searchProducts.total_count > pageSize"
    @update:page="pageNumber"
    :pagination="pagination"
    :totalCount="searchProducts.total_count"
  ></Pagination>
</template>

<script>
// import Header5 from "./../components/Headers/Header5.vue";
import SearchForm from "./../components/Forms/Search.vue";
import ProductListview from "./../components/Products/ProductListview.vue";
import Modal from "bootstrap.native/src/components/modal-native";
import ModalCart from "./../components/Products/ModalCart.vue";
import Pagination from "./../components/Misc/Pagination.vue";
import { mapGetters, mapActions } from "vuex";
import { ContentLoader } from "vue-content-loader";

export default {
  components: {
    ProductListview,
    SearchForm,
    Pagination,
    ModalCart,
    ContentLoader

    // Header5
  },
  // emits: ["update:search"],
  data: () => ({
    cartType: "",
    modal: false,
    modalProduct: {},
    searchValueResult: "",
    pagination: {
      pageSize: 6,
      currentPage: 1,
      numberOfPages: 0
    },
    sortOption: "relevance:DESC",
    sortOptionParam: "-relevance",
    products: {},
    product: {},
    pageSize: 24,
    totalCount: 0,
    header: [
      {
        subtitle: "",
        title: "Search",
        desc: "",
        buttons: []
      }
    ],
    awaitingSearch: false
  }),
  computed: mapGetters(["searchProducts"]),
  created() {
    if (this.$route.query.p) {
      this.pagination.currentPage = this.$route.query.p;
    }
    if (this.$route.query.s) {
      this.sortOption = this.$route.query.s;
    }
  },
  methods: {
    ...mapActions(["fetchSearchProducts"]),
    initProductModal(value) {
      this.cartType = value.cartType;
      this.modalProduct = value.product;
      this.modal = true;
      const productModal = document.getElementById("product-modal");
      const productModalInit = new Modal(productModal);
      productModalInit.show();
    },
    sortChanged: function() {
      this.searchUpdate(this.searchValueResult);
    },
    pageNumber: function(value) {
      this.pagination.currentPage = value;
      this.searchUpdate(this.searchValueResult);
    },
    searchUpdate: function(value) {
      // TODO: Reset &p= when keyword changes
      if (typeof value == "undefined") {
        return;
      }
      if (value !== this.searchValueResult) {
        this.pagination.currentPage = 1;
      }

      this.searchValueResult = value;
      this.currentPage = this.pagination.currentPage;

      this.$router.replace({
        name: "SearchForm",
        params: { searchSlug: "" },
        query: {
          q: this.searchValueResult,
          p: this.currentPage,
          s: this.sortOption
        }
      });

      setTimeout(() => {
        this.fetchSearchProducts({
          sortOption: this.sortOption,
          pageNumber: this.currentPage,
          searchValue: this.searchValueResult
        });
        this.pagination.totalCount = this.searchProducts.totalCount;
        this.awaitingSearch = false;
      }, 1000);
      this.awaitingSearch = true;
    }
  }
};
</script>

<style lang="css" scoped></style>
