<template>
  <form v-on:submit.prevent="noop">
    <div class="form-group">
      <div class="search-input">
        <input
          type="text"
          class="form-control form-control-lg form-control--shadow search-input__field"
          id="input-search"
          aria-describedby="search"
          :placeholder="placeholder"
          v-model="searchValue"
          @input="searchChanged"
        />

        <button
          class="btn btn-secondary btn-icon-only search-input__button d-none"
          title=""
          target=""
        >
          <i class="icon icon-search"></i>
        </button>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  components: {},
  props: ["placeholder"],
  // emits: ["update:search"],
  data: function() {
    return {
      searchValue: this.$route.query.q
    };
  },
  created: function() {
    this.$emit("update:search", this.searchValue);
  },
  methods: {
    searchChanged() {
      this.$emit("update:search", this.searchValue);
      // this.$router.replace({
      //   name: "Search",
      //   params: { searchSlug: "" },
      //   query: { q: this.searchValue, p: 1 }
      // });
    }
  }
};
</script>

<style lang="css" scoped></style>
